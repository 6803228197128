var observeDOM = (function(){
    var MutationObserver = window.MutationObserver || window.WebKitMutationObserver,
        eventListenerSupported = window.addEventListener;

    return function(obj, callback){
        if( MutationObserver ){
            // define a new observer
            var obs = new MutationObserver(function(mutations, observer){
                if( mutations[0].addedNodes.length || mutations[0].removedNodes.length )
                    callback();
            });
            // have the observer observe foo for changes in children
            obs.observe( obj, { childList:true, subtree:true });
        }
        else if( eventListenerSupported ){
            obj.addEventListener('DOMNodeInserted', callback, false);
            obj.addEventListener('DOMNodeRemoved', callback, false);
        }
    };
})();


function showMainPage() {
    $('body').addClass('body-main')
    $('.panel').hide()
    $('#main-panel').show()
}

function showContactPage() {

    $('body').addClass('body-contact')

    $('#mce-error-response').empty();
    $('#mce-success-response').empty();

    $('.panel').hide()
    $('#contact-panel').show()

    GTMEvent('contactform-show');

    window.scrollTo(0,0)
}


function gotoMainPage() {
    window.location.hash = ''
}

function gotoContactPage() {
    window.location.hash = 'contact'
}

function gotoSuccessPage() {
    window.location.hash = 'success'
}

function GTMEvent(eventName) {

    if(dataLayer){
        dataLayer.push({
            'event': eventName,
        })    
    }
}

function showSuccessPage() {
    $('body').addClass('body-success')

    $('#mce-error-response').empty();
    $('#mce-success-response').empty();
    
    $('.panel').hide()
    $('#contact-success-panel').show()

    GTMEvent('contactform-submitted');
    window.scrollTo(0,0)
}

function handleHashChange() {
    switch(window.location.hash) {
        case '#contact':
            showContactPage();
            break;
        case '#success':
            showSuccessPage();
            break;
        default:
            showMainPage();
            break;    
    }
}

function initContactForm () {

    $('.show-contact').on('click', function(event) {
        event.preventDefault();
        gotoContactPage()
        return false;
    })

    $('#hide-success').on('click', function(event) {
        event.preventDefault();
        gotoMainPage()
        return false;
    })

    $('#contact-cancel').on('click', function(event) {
        event.preventDefault();
        gotoMainPage()
        return false;
    })

    observeDOM( document.getElementById('mce-success-response') ,function(){ 
        console.log('form submit done');
        gotoSuccessPage()
    });

    window.addEventListener('hashchange', handleHashChange, false )
  
    handleHashChange()

}


module.exports.initContactForm = initContactForm
