var contact = require('js/contact')
var preload = require('js/preload')

document.addEventListener('DOMContentLoaded', () => {
  // do your setup here

  preload.preload();
  contact.initContactForm()

  function menuScroll() {
    var scrollTop = $(window).scrollTop()

    if( scrollTop === 0){
      $('header').removeClass('scrolled')
    }
    else {
      $('header').addClass('scrolled')
    }
  }

  $(window).scroll( menuScroll)
  menuScroll()


  function resizeThings() {

    $('[data-resize-ratio]').each( function() {

      var aspectRatio = parseFloat($(this).data('resize-ratio'))
      var width = $(this).width()
      var height = 1/aspectRatio * width
      $(this).height(height)
    })
  }


  $(window).resize(resizeThings)

  resizeThings()


  $('.person.matt a').on('click', function() {
    dataLayer.push({'event': 'profile-matt'});
  })

  $('.person.richard a').click( function() {
    dataLayer.push({'event': 'profile-richard'});
  })

  $('.person.spencer a').click( function() {
    dataLayer.push({'event': 'profile-spencer'});
  })

  $(document).keyup( function(e) {
    if(e.key === 'Escape') {
      window.location.hash = '' //gotoMainPage
    }
  })

  setTimeout( function() {
     $('.scroll-hint').css({opacity: 0.8});
   }, 1000)

  console.log('Initialized app')
  
});

